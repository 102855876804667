import React, { useState } from "react";
import { Form, Button, Input, message, Modal } from "antd";
import axios from "axios";
import { IconButton } from "@mui/material";
import { DownOutlined } from "@ant-design/icons";
import "../Styles/CreateVendor.css";
const CreateVendor = () => {
  const { TextArea } = Input;

  const [nameArabic, setNameArabic] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [nameKurdish, setNameKurdish] = useState("");
  const [number, setNumber] = useState(0);
  const [logo, setLogo] = useState(null);
  const [addressArabic, setAddressArabic] = useState("");
  const [addressKurdish, setAddressKurdish] = useState("");

  const [form] = Form.useForm();
  //todo this is Modal  Name
  const [isModalOpenNew, setIsModalOpenNew] = useState(false);

  const handleCancelNew = () => {
    setIsModalOpenNew(false);
  };
  const showModalNew = () => {
    setIsModalOpenNew(true);
  };

  const handleOkNew = () => {
    setIsModalOpenNew(false);
  };
  //! End modal
  //todo this is Modal  address
  const [isModalOpenNew2, setIsModalOpenNew2] = useState(false);

  const handleCancelNew2 = () => {
    setIsModalOpenNew2(false);
  };
  const showModalNew2 = () => {
    setIsModalOpenNew2(true);
  };

  const handleOkNew2 = () => {
    setIsModalOpenNew2(false);
  };
  //! End modal
  const PostApi = async () => {
    if (!logo) {
      // Handle case where image_url is null
      message.error("Please upload an image.");
      return;
    }
    // const URI = "https://bardawamplus.com/api/register-store";
    const URI = "https://api.bardawamplus.com/api/register-store";

    // Create a FormData object
    const formData = new FormData();

    // Append text fields to FormData
    formData.append(
      "name",
      JSON.stringify({ ar: nameArabic, ku: nameKurdish })
    );
    formData.append(
      "address",
      JSON.stringify({ ar: addressArabic, ku: addressKurdish })
    );
    formData.append("password", password);
    formData.append("email", email);
    formData.append("number", number);
    formData.append("area_id", 1);

    // if (logo) {
    formData.append("logo", logo); // 'logo' is the File object
    // }

    try {
      const res = await fetch(URI, {
        method: "POST",
        body: formData, // Pass formData directly as body
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.statusText}`);
      }

      // eslint-disable-next-line no-unused-vars
      const responseJson = await res.json();
      // console.log("Response:", responseJson);

      // Reset form and show success message
      form.resetFields();
      message.success("Successfully added member");
      window.location.href = "/";
    } catch (error) {
      message.error("Error: " + error.message);
    }
  };

  //todo ---------------------------------------------------------------------
  const onFinish = (values) => {
    // console.log("Success:", values);
    // message.success("Success Post ");
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };
  const handleResetCart = () => {
    form.resetFields();
  };
  //todo  -----------------------------------------------
  //todo  ----------------------------------------------
  //todo this is Image========================================
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleImageChange = async (file) => {
    // Generate a preview URL for the image file
    const previewUrl = URL.createObjectURL(file);
    setImagePreviewUrl(previewUrl);

    // Proceed with the upload logic
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      "https://bardawamplus.com/api/upload-file",
      formData
    );

    if (response.status === 200) {
      const LogoUrl = response.data.path;
      setLogo(LogoUrl);
      // message.success("Image uploaded successfully");

      // Revoke the preview URL after upload is successful
      URL.revokeObjectURL(previewUrl);
    } else {
      message.error("Failed to upload image");
    }
  };
  const normFile = (e) => {
    return e && e.fileList ? [e.file] : [];
  };
  const onChangeUpload = async (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      await handleImageChange(file);
    }
  };
  //todo  -----------------------------------------------

  //todo  -----------------------------------------------
  //todo  -----------------------------------------------
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>Add new Vendor</h1>
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* //todo this is image  */}
        <div className="centered-form-item">
          <Form.Item
            name="logo"
            // label={"image"}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                type: "text",
                required: true,
                message: "Image is invalid",
              },
            ]}
          >
            <input
              id="single-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeUpload}
            />
            <label htmlFor="single-image-upload">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                className="upload-button"
              >
                AddImage
              </IconButton>
            </label>
            {imagePreviewUrl ? (
              <img
                src={imagePreviewUrl}
                alt="Preview of the selected file"
                className="image-preview"
              />
            ) : logo ? (
              <img
                src={logo}
                alt="Uploaded file preview"
                className="image-preview"
              />
            ) : null}
          </Form.Item>
        </div>
        {/* // todo this iS Imagess
        <Form.Item
          name="file"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <input
            // accept="image/*"
            id="image-upload"
            type="file"
            style={{ display: "none" }}
            onChange={onChangeUpload}
          />
          <label htmlFor="image-upload">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              add image
            </IconButton>
          </label>
          {logo && (
            <img
              src={URL.createObjectURL(logo)}
              alt="Uploaded"
              style={{ maxWidth: "100px", maxHeight: "100px" }}
            />
          )}
        </Form.Item> */}

        {/* //! end image ================================= */}

        {/* //todo this is name  */}
        <div className="NameAdders">
          <Form.Item name="name">
            <Button type="text" className="ButtonName" onClick={showModalNew}>
              <div className="OnButton">
                Name
                <DownOutlined />
              </div>
            </Button>

            <Modal
              title="Name"
              open={isModalOpenNew}
              onOk={handleOkNew}
              onCancel={handleCancelNew}
              footer={
                <div>
                  <Button
                    type="primary"
                    onClick={handleOkNew}
                    style={{
                      width: "30%",
                    }}
                  >
                    save
                  </Button>
                  <span> </span>
                  <Button onClick={handleCancelNew}>cancel</Button>
                </div>
              }
            >
              <Form
                form={form}
                onFinishFailed={onFinishFailed}
                initialValues={{ remember: true }}
              >
                <Form.Item name="TitleKurdish" label="Kurdish">
                  <Input
                    placeholder="Type your Title Kurdish"
                    value={nameKurdish}
                    onChange={(e) => setNameKurdish(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="TitleArabic" label="Arabic">
                  <Input
                    placeholder="Type your Title Arabic"
                    value={nameArabic}
                    onChange={(e) => setNameArabic(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Form.Item>
        </div>
        {/* //todo ----------------------------------------------- */}

        <Form.Item name="number" label="-">
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="Number Phone"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="password"
          rules={[
            { required: true, message: "Please input your password!" },
            { min: 6, message: "Password must be at least 6 characters." },
          ]}
        >
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        {/* //todo this is address */}
        <div className="NameAdders">
          <Form.Item name="name">
            <Button type="text" className="ButtonName" onClick={showModalNew2}>
              <div className="OnButton">
                Address
                <DownOutlined />
              </div>
            </Button>

            <Modal
              title="address"
              open={isModalOpenNew2}
              onOk={handleOkNew2}
              onCancel={handleCancelNew2}
              footer={
                <div>
                  <Button
                    type="primary"
                    onClick={handleOkNew2}
                    style={{
                      width: "30%",
                    }}
                  >
                    save
                  </Button>
                  <span> </span>
                  <Button onClick={handleCancelNew2}>cancel</Button>
                </div>
              }
            >
              <Form
                form={form}
                onFinishFailed={onFinishFailed}
                initialValues={{ remember: true }}
              >
                <Form.Item name="addressKurdish" label="address Kurdish">
                  <TextArea
                    showCount
                    maxLength={250}
                    // onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    value={addressKurdish}
                    onChange={(e) => setAddressKurdish(e.target.value)}
                    placeholder="Type your Title Kurdish"
                  />
                </Form.Item>
                <Form.Item name="addressArabic" label="address Arabic">
                  <TextArea
                    showCount
                    maxLength={250}
                    // onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    placeholder="Type your Title Arabic"
                    value={addressArabic}
                    onChange={(e) => setAddressArabic(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Form.Item>
        </div>
        {/* //! ----------------------------------------------- */}
        <Form.Item label=".">
          <Button type="primary" htmlType="submit" onClick={PostApi}>
            Submit
          </Button>
          <span style={{ margin: "0 8px" }}></span>
          <Button type="primary" danger onClick={handleResetCart}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateVendor;
