import { Table, Button, Space, Modal, Form, InputNumber, message } from "antd";

import { useState, useEffect } from "react";

const Wallet = () => {
  const [dataSource, setDataSource] = useState([]);

  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [newBalance, setNewBalance] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    getApiProducts();
  }, []);

  const getApiProducts = async () => {
    try {
      const res = await fetch("https://bardawamplus.com/api/stores");
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();

      // Use Promise.all to handle the asynchronous map properly
      const dataSource = await Promise.all(
        data.data.map(async (item) => {
          const nameObj = JSON.parse(item.name);
          const descriptionObj = JSON.parse(item.address);

          const balance = (
            await (
              await fetch(`https://bardawamplus.com/api/users/${item.owner_id}`)
            ).json()
          ).balance;

          return {
            owner_id: item.owner_id,
            nameKurdish: nameObj.ku || "N/A",
            nameArabic: nameObj.ar || "N/A",
            logo: item.logo
              ? `https://api.bardawamplus.com/storage/${item.logo}`
              : null,
            number: item.number,
            balance: balance,
            detailedKU: descriptionObj.ku || "N/A",
            detailedAr: descriptionObj.ar || "N/A",
          };
        })
      );

      // Set the dataSource once the Promise.all resolves
      setDataSource(dataSource);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("userDataSource");
    if (savedData) {
      setDataSource(JSON.parse(savedData));
    }
  }, []);

  const handleUpdateBalance = async (owner_id, newBalance) => {
    try {
      // Update newBalance value in the local state
      setNewBalance(newBalance);

      const response = await fetch(
        // `https://bardawamplus.com/api/users/${owner_id}/update-balance`,
        `https://bardawamplus.com/api/users/${owner_id}/updblcudps`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ balance: newBalance }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update balance");
      }

      // Update the balance in the dataSource state
      const updatedData = dataSource.map((item) => {
        if (item.owner_id === owner_id) {
          return { ...item, balance: newBalance };
        }
        return item;
      });
      setDataSource(updatedData);
      // console.log(updatedData);

      // Close the update modal
      setUpdateModalVisible(false);
      message.success("success to change Balance");
      // Print the app variable
      // eslint-disable-next-line no-undef
      // console.log("App variable:", app);
    } catch (error) {
      console.error("Error updating balance:", error);
    }
  };
  const columns = [
    {
      title: "name",
      dataIndex: "nameKurdish",
      key: "nameKurdish",
    },

    {
      title: "logo",
      dataIndex: "logo",
      key: "logo",
      render: (item) => {
        // console.log("Image URL:", item);
        if (item) {
          return (
            <img
              src={item}
              alt="img"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    // {
    //   title: "balance",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    {
      title: "Store balance ",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => (
        <Space size="middle">
          <span>{text}</span>
          <Button
            type="default"
            style={{
              backgroundColor: "green",
              borderColor: "green",
              color: "white",
            }}
            onClick={() => showUpdateModal(record.owner_id)}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];
  const showUpdateModal = (owner_id) => {
    const newBalance =
      dataSource.find((item) => item.owner_id === owner_id)?.balance || 0;
    setNewBalance(newBalance);
    setSelectedUserId(owner_id);
    setUpdateModalVisible(true);
  };

  const handleModalOk = () => {
    handleUpdateBalance(selectedUserId, newBalance);
  };

  return (
    <div>
      <Table
        dataSource={dataSource.map((item) => ({ ...item, key: item.owner_id }))}
        columns={columns}
      />

      <Modal
        title="Update Balance"
        open={updateModalVisible}
        onOk={handleModalOk}
        onCancel={() => setUpdateModalVisible(false)}
      >
        <Form>
          <Form.Item label="New Balance">
            <InputNumber
              value={newBalance}
              onChange={(value) => setNewBalance(value)}
              min={0}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Wallet;
