import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import { Table, Button, message, Modal, Input } from "antd";
// import axios from"axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { UploadOutlined } from "@ant-design/icons";
const Products = () => {
  //todo -----------------Search-----------
  const [SearchText, setSearchText] = useState("");
  //todo----------------------------------------------
  const [dataSource, setDataSource] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    getApiProducts();
  }, []);

  const getApiProducts = async () => {
    try {
      // Fetch data from both endpoints concurrently
      const [pinnedRes, allRes] = await Promise.all([
        fetch("https://bardawamplus.com/api/products/pinned"),
        fetch("https://bardawamplus.com/api/products"),
      ]);

      // Check if both requests are successful
      if (!pinnedRes.ok || !allRes.ok) {
        throw new Error(
          `HTTP error! Status: ${pinnedRes.status}, ${allRes.status}`
        );
      }

      // Parse the responses
      const pinnedData = await pinnedRes.json();
      const allData = await allRes.json();

      // Combine and map data from both responses (customize as per your needs)
      const combinedData = [...pinnedData.data, ...allData.data];

      setDataSource(
        combinedData.map((item) => {
          const nameObj = JSON.parse(item.name);
          const nameStore = JSON.parse(item.store.name);

          return {
            id: item.id,
            nameKurdish: nameObj.ku || "N/A",
            nameStore: nameStore.ar || "N/A",

            Price: item.price,
            image_url: item.image_url
              ? `https://bardawamplus.com/api/storage/${item.image_url}`
              : null,
            logo:
              item.store && item.store.logo
                ? `https://bardawamplus.com/api/storage/${item.store.logo}`
                : null,
            discounted_price: item.discounted_price,

            points: item.points,
            P_S: item.pinned,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //todo remove item
  const handleDelete = (id) => {
    setDeleteItemId(id);
    setIsDeleteModalVisible(true);
  };
  const handleConfirmedDelete = async () => {
    try {
      // Route::delete('/products/{id}/dppddbps', [ProductController::class, 'delete']);
      await fetch(
        `https://bardawamplus.com/api/products/${deleteItemId}/dppddbps`,
        {
          method: "DELETE",
        }
      );

      // message.success("Product deleted successfully");
      // console.log("Product deleted successfully");

      // Log the result of getApiProducts
      // eslint-disable-next-line no-unused-vars
      const updatedData = await getApiProducts();
      // console.log("Updated Data:", updatedData);

      // Reset deleteItemId and hide the modal
      setDeleteItemId(null);
      setIsDeleteModalVisible(false);
    } catch (error) {
      console.error("Error deleting product:", error);
      message.error("Error deleting product");
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      filteredValue: [SearchText],
      onFilter: (value, record) => {
        return (
          String(record.nameKurdish)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.nameArabic)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Products",
      dataIndex: "nameKurdish",
      key: "nameKurdish",
    },
    {
      title: "Stores",
      dataIndex: "nameStore",
      key: "nameStore",
    },

    {
      title: "logo",
      dataIndex: "logo",
      key: "logo",
      render: (item) => {
        // console.log("Image URL:", item);
        if (item) {
          return (
            <img
              src={item}
              alt="img"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },

    {
      title: "image_url",
      dataIndex: "image_url",
      key: "image_url",
      render: (item) => {
        // console.log("Image URL:", item);
        if (item) {
          return (
            <img
              src={item}
              alt="img"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },

    {
      title: "products",
      dataIndex: "P_S",
      key: "pinned",
      render: (pinned) => {
        return (
          <span className={pinned === 0 ? "pinned-products" : "pinned-slider"}>
            {pinned === 0 ? "Products" : "VIP"}
          </span>
        );
      },
    },
    {
      title: "points",
      dataIndex: "points",
      key: "points",
    },

    {
      title: "Delete",
      key: "action",
      render: (text, record) => (
        <div>
          {/* <Button type="primary" onClick={() => handleUpdate(record)}>
            Update
          </Button> */}

          <span> </span>
          <Button
            type="danger"
            style={{ background: "red", border: "red", color: "white" }}
            onClick={() => handleDelete(record.id)}
            icon={<DeleteOutlined />}
          ></Button>
        </div>
      ),
    },
    {
      title: "Edit",
      dataIndex: "Action",
      key: "action",
      render: (text, record) => (
        <Link to={`/Products/UpdateProducts/${record.id}`}>
          {text}
          <Button type="primary" icon={<EditOutlined />}></Button>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Input.Search
        placeholder="Search here ...."
        style={{
          marginBottom: 15,
          marginTop: 12,
          width: 300,
        }}
        onSearch={(value) => {
          setSearchText(value);
        }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <Table
        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
        pagination={false} // Corrected typo here
      />
      {/* Delete Modal */}
      <Modal
        title="Confirm Deletion"
        open={isDeleteModalVisible}
        onOk={handleConfirmedDelete}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete this product?</p>
      </Modal>
    </div>
  );
};

export default Products;
