import {
  EditOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Table, Button, message, Modal } from "antd";

import { animateScroll as scroll } from "react-scroll";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/AllVendor.css";
const AllVendor = () => {
  //todo -----------------Search-----------

  //todo----------------------------------------------
  const [dataSource, setDataSource] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    getApiProducts();
  }, []);

  const getApiProducts = async () => {
    try {
      const res = await fetch("https://bardawamplus.com/api/stores");
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      // console.log("data", data.data);
      setDataSource(
        data.data.map((item) => {
          const nameObj = JSON.parse(item.name);
          const descriptionObj = JSON.parse(item.address);

          return {
            id: item.id,
            owner_id: item.owner_id,
            store_id: item.store_id,
            nameKurdish: nameObj.ku || "N/A",
            nameArabic: nameObj.ar || "N/A",

            logo: item.logo
              ? `https://api.bardawamplus.com/storage/${item.logo}`
              : null,
            number: item.number,
            balance: item.balance_owner,
            detailedKU: descriptionObj.ku || "N/A",
            detailedAr: descriptionObj.ar || "N/A",
          };
        })
      );
      // console.log("setDataSource processed data:", setDataSource);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //todo remove item
  const handleDelete = (id) => {
    setDeleteItemId(id);
    setIsDeleteModalVisible(true);
  };
  const handleConfirmedDelete = async () => {
    try {
      // Route::delete('/stores/{id}/dstbdp', [StoreController::class, 'deleteStore']);
      // await fetch(`https://bardawamplus.com/api/stores/${deleteItemId}`, {
      await fetch(
        `https://bardawamplus.com/api/stores/${deleteItemId}/dstbdp`,
        {
          method: "DELETE",
        }
      );

      message.success("Store deleted successfully");

      // Log the result of getApiProducts
      // eslint-disable-next-line no-unused-vars
      const updatedData = await getApiProducts();
      // console.log("Updated Data:", updatedData);

      setDeleteItemId(null);
      setIsDeleteModalVisible(false);
    } catch (error) {
      message.error("Error deleting Store");
    }
  };

  const columns = [
    {
      title: "name",
      dataIndex: "nameKurdish",
      key: "nameKurdish",
    },
    {
      title: "logo",
      dataIndex: "logo",
      key: "logo",
      render: (item) => {
        // console.log("Image URL:", item);
        if (item) {
          return (
            <img
              src={item}
              alt="img"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },

    {
      title: "Delete",
      key: "action",
      render: (text, record) => (
        <div>
          <span> </span>
          <Button
            type="danger"
            style={{ background: "red", border: "red", color: "white" }}
            onClick={() => handleDelete(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: "Edit",
      dataIndex: "Action",
      key: "action",
      render: (text, record) => (
        <Link to={`/AllVendor/UpdateVendor/${record.id}`}>
          {text}
          <Button type="primary" icon={<EditOutlined />}></Button>
        </Link>
      ),
    },
  ];
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      <Button
        type="primary"
        className="scroll-to-top-button"
        onClick={scrollToTop}
        // icon={<ArrowUpOutlined />}
      >
        <ArrowUpOutlined />
      </Button>
      <Link to="/CreateVendor">
        <Button
          type="primary"
          className="navigate-to-new-page-button"
          // icon={<PlusOutlined />}
        >
          <PlusOutlined />
        </Button>
      </Link>
      <Table
        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
        pagination={false}
      />

      {/* Delete Modal */}
      <Modal
        title="Confirm Deletion"
        open={isDeleteModalVisible}
        onOk={handleConfirmedDelete}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete this product?</p>
      </Modal>
    </div>
  );
};

export default AllVendor;
