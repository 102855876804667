import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Profile from "./FrontEnd/pages/Profile";

import Dashboard from "./FrontEnd/pages/dashboard";
import OrderTable from "./FrontEnd/pages/OrderTable";
import OrderDetails from "./FrontEnd/pages/Order";
import Network from "./FrontEnd/pages/Network";

import Products from "./FrontEnd/pages/ProductAdd";
import NotFound from "./FrontEnd/pages/NotFound";
import NavberUpdate from "./FrontEnd/Navbar/NavberUpdate";

import axios from "axios";
import UpdateProducts from "./FrontEnd/pages/UpdateProducts";
import AllVendor from "./FrontEnd/Register/AllVendor";
import UpdateVendor from "./FrontEnd/Register/UpdateVendor";
import CreateVendor from "./FrontEnd/Register/CreateVendor";
import Wallet from "./FrontEnd/pages/Wallet";

const Home = ({ dataSource }) => {
  const [store, setStore] = useState({});
  const [storeProducts, setStoreProducts] = useState({});
  useEffect(() => {
    axios
      .get(`https://bardawamplus.com/api/stores`)
      .then((response) => {
        // Ensure correct access to response data
        setStore(response.data.data);
        console.log("response.data.data", response.data.data);
      })

      .catch((error) => {
        console.error("Error fetching order details:", error);
      });
  }, []);
  useEffect(() => {
    // Fetch data from both endpoints concurrently
    Promise.all([
      axios.get(`https://bardawamplus.com/api/products`),
      axios.get(`https://bardawamplus.com/api/products/pinned`),
    ])
      .then(([productsResponse, pinnedResponse]) => {
        // Handle the responses
        const combinedData = [
          ...productsResponse.data.data,
          ...pinnedResponse.data.data,
        ];
        setStoreProducts(combinedData);
        console.log("Combined data", combinedData);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  return (
    <div>
      <NavberUpdate dataSource={dataSource} />

      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="*" element={<NotFound />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Products" element={<Products />} />
        <Route
          path="/Products/UpdateProducts/:id"
          element={<UpdateProducts storeProducts={storeProducts} />}
        />
        <Route path="/OrderTable" element={<OrderTable />} />
        <Route path="/Wallet" element={<Wallet />} />
        <Route path="/OrderTable/OrderDetails/:id" element={<OrderDetails />} />
        <Route path="/Profile/Network/:id" element={<Network />} />
        <Route path="/AllVendor" element={<AllVendor />} />
        <Route path="/CreateVendor" element={<CreateVendor />} />
        <Route
          path="/AllVendor/UpdateVendor/:id"
          element={<UpdateVendor store={store} />}
        />
      </Routes>
    </div>
  );
};

export default Home;
