/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../Styles/order.css";
import { List, Button, Spin } from "antd";

const OrderDetails = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    axios
      .get(`https://bardawamplus.com/api/orders/${id}`)
      .then((response) => {
        setOrderDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching order details:", error);
      });
  }, [id]);

  useEffect(() => {
    if (selectedStatus) {
      handleStatusUpdate(selectedStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  const handleStatusUpdate = (status) => {
    // Route::put('/orders/updateStatus/{id}/ptordpbdps', [OrderController::class, 'updateStatus']);
    axios
      // .put(`https://bardawamplus.com/api/orders/updateStatus/${id}`, {
      .put(
        `https://bardawamplus.com/api/orders/updateStatus/${id}/ptordpbdps`,
        {
          status: status,
        }
      )
      .then((response) => {
        setOrderDetails((prevOrderDetails) => ({
          ...prevOrderDetails,
          status: response.data.status,
        }));
        // Reload the page after status update
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating order status:", error);
      });
  };
  if (!orderDetails) {
    return (
      <div>
        <Spin tip="Loading...">
          <div style={{ width: "100%", height: "100vh" }} />
        </Spin>
      </div>
    );
  }

  return (
    <div>
      <div className="order-details-container">
        <span> </span>
        <Button
          type="button"
          style={{
            backgroundColor: "blue",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => setSelectedStatus(1)}
        >
          Received
        </Button>
        <span> </span>
        <Button
          type="button"
          style={{
            backgroundColor: "yellow",
            color: "black",
            fontWeight: "bold",
          }}
          onClick={() => setSelectedStatus(2)}
        >
          Delivery
        </Button>
        <span> </span>
        <Button
          type="button"
          style={{
            backgroundColor: "green",
            fontWeight: "bold",
            color: "white",
          }}
          onClick={() => setSelectedStatus(3)}
        >
          Completed
        </Button>
        <span> </span>
        <Button
          type="button"
          style={{ backgroundColor: "red", fontWeight: "bold", color: "white" }}
          onClick={() => setSelectedStatus(4)}
        >
          Cancelled
        </Button>

        <h2 className="order-details-title">Order </h2>
        <div className="order-details-content">
          <p>
            <span className="detail-label">Username:</span>{" "}
            {orderDetails.username}
          </p>
          <p>
            <span className="detail-label">sub amount:</span>{" "}
            {orderDetails.sub_amount}
          </p>
          <p>
            <span className="detail-label">Total Delivery in Erbil:</span>
            3000
          </p>
          <p>
            <span className="detail-label">sub amount:</span>{" "}
            {orderDetails.sub_amount + 3000}
          </p>
          <p>
            <span className="detail-label">Total Delivery IN Kurdstan:</span>
            5000
          </p>
          <p>
            <span className="detail-label">sub amount:</span>{" "}
            {orderDetails.sub_amount + 5000}
          </p>
          <p>
            <span className="detail-label">Total Delivery oUT Kurdstan:</span>
            8000
          </p>
          <p>
            <span className="detail-label">sub amount:</span>{" "}
            {orderDetails.sub_amount + 8000}
          </p>
          <p>
            <span className="detail-label">Phone Number:</span>
            {orderDetails.phone_number}
          </p>
          <p>
            <span className="detail-label">Address:</span>
            {orderDetails.address}
          </p>
          <p>
            <span className="detail-label">Note:</span> {orderDetails.note}
          </p>
          <List>
            <hr
              style={{
                border: "none",
                borderWidth: "bold",
                borderTop: "1px solid #aaaaaa",
                margin: "23px 0",
              }}
            />
            {orderDetails.products.map((product, index) => (
              <List.Item key={index}>
                <span className="detail-label"></span>
                <div className="product-info">
                  Store Name: {renderProductVendor(product.store.name)}
                  <br />
                  Number of Vendor: {product.store.number}
                  <br />
                  Name Product : {renderProductName(product.name)}
                  <br />
                  price: {product.discounted_price}
                  <br />
                  discounted price: {product.store_id}
                  <br />
                  quantity: {product.quantity}
                  <br />
                  points: {product.points}
                </div>
                <img
                  height={150}
                  width={150}
                  src={`https://bardawamplus.com/api/storage/${product.image_url}`}
                  alt={`Order Image - ${product.name}`}
                />
              </List.Item>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
  //todo name products
  function renderProductName(nameJSON) {
    try {
      const parsedName = JSON.parse(nameJSON);
      // Assuming that 'ar' and 'ku' are the language codes for Arabic and Kurdish
      const productName = parsedName.ku || parsedName.ar || "Unnamed Product";
      return <span>{productName}</span>;
    } catch (error) {
      console.error("Error parsing product name JSON:", error);
      return <span>Error parsing product name</span>;
    }
  }
};
//todo vendor
function renderProductVendor(vendorJSON) {
  try {
    const VendorName = JSON.parse(vendorJSON);
    // Assuming that 'ar' and 'ku' are the language codes for Arabic and Kurdish
    const productVendorName =
      VendorName.ku || VendorName.ar || "Unnamed Product";
    return <span>{productVendorName}</span>;
  } catch (error) {
    console.error("Error parsing product name JSON:", error);
    return <span>Error parsing product name</span>;
  }
}

export default OrderDetails;
