import "../Styles/UpdateAllProduct.css";
import { Button, Form, Input, message, Modal, Switch } from "antd";

import { DownOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import React, { useEffect, useState } from "react";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import { IconButton } from "@mui/material";

const UpdateProducts = ({ storeProducts }) => {
  // eslint-disable-next-line no-unused-vars
  const { id } = useParams();
  const product = Array.isArray(storeProducts)
    ? storeProducts.find((item) => item.id === parseInt(id))
    : null;
  const [image_url, setImage_url] = useState(null);
  const [form] = Form.useForm();
  //todo this is Modal  Name
  const [isModalOpenNew, setIsModalOpenNew] = useState(false);
  // const [form] = Form.useForm(); // Form instance for the outer form
  const handleCancelNew = () => {
    setIsModalOpenNew(false);
  };
  const showModalNew = () => {
    setIsModalOpenNew(true);
  };

  const handleOkNew = () => {
    setIsModalOpenNew(false);
  };
  //! End modal
  //todo this is Modal  Description
  const [isModalOpenNew2, setIsModalOpenNew2] = useState(false);
  // const [form] = Form.useForm(); // Form instance for the outer form
  const handleCancelNew2 = () => {
    setIsModalOpenNew2(false);
  };
  const showModalNew2 = () => {
    setIsModalOpenNew2(true);
  };

  const handleOkNew2 = () => {
    setIsModalOpenNew2(false);
  };
  //! End modal
  //todo this is get API for User
  // const [loading, setLoading] = useState(true);

  //todo this is get API for User
  useEffect(() => {
    if (product) {
      const nameObj = JSON.parse(product.name);
      const descriptionObj = JSON.parse(product.description);
      form.setFieldsValue({
        TitleKurdish: nameObj.ku || "",
        TitleArabic: nameObj.ar || "",
        Price: product.price || "",
        pinned: product.pinned || "",
        Discount: product.discounted_price || "",
        points: product.points || "",
        DescriptionKurdish: descriptionObj.ku || "",
        DescriptionArabic: descriptionObj.ar || "",
      });

      const imageUrl = product.image_url ? `${product.image_url}` : null;
      // console.log("imageUrl 111:", imageUrl);
      setImage_url(imageUrl);
      // console.log("Image URL:", imageUrl); // Log the image URL

      setImage_url(imageUrl);
    }
  }, [product, form]);

  //todo Image =============================================
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const handleImageChange = async (file) => {
    // Generate a preview URL for the image file
    const previewUrl = URL.createObjectURL(file);
    setImagePreviewUrl(previewUrl);
    // Proceed with the upload logic
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(
      "https://bardawamplus.com/api/upload-file",
      formData
    );
    if (response.status === 200) {
      const imageUrl = response.data.path;
      setImage_url(imageUrl);
      // message.success("Image uploaded successfully");
      // Revoke the preview URL after upload is successful
      URL.revokeObjectURL(previewUrl);
    } else {
      message.error("Failed to upload image");
    }
  };
  const normFile = (e) => {
    return e && e.fileList ? [e.file] : [];
  };
  const onChangeUpload = async (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      await handleImageChange(file);
    }
  };

  //todo update user

  const onFinish = async (values) => {
    try {
      const name = {
        ar: form.getFieldValue("TitleArabic"),
        ku: form.getFieldValue("TitleKurdish"),
      };
      const description = {
        ar: form.getFieldValue("DescriptionArabic"),
        ku: form.getFieldValue("DescriptionKurdish"),
      };
      const updatedValues = {
        ...values,
        pinned: values.pinned ? 1 : 0,
        points: values.points,
        name: name,
        description: description,
        discounted_price: values.Discount, // Rename 'Discount' to 'discounted_price'
        price: values.Price, // Use 'Price' as 'price'
        image_url: image_url,
      };
      // Route::put('/products/{id}/ptpdbdps', [ProductController::class, 'update']);
      const response = await axios.put(
        // `https://bardawamplus.com/api/products/${id}/ptpdbdps`,
        `https://bardawamplus.com/api/products/${id}/ptpdbdps`,
        updatedValues
      );

      if (response.status === 200) {
        message.success("Successfully updated");
        window.location.href = "/Products";
      }
    } catch (error) {
      message.error("Failed to update");
    }
  };

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const onFinishFailed = () => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>
        update Product{" "}
      </h1>

      {/* //! end image ================================= */}
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* //todo this is image  */}
        <div className="centered-form-item">
          <Form.Item
            name="Logo"
            // label="Logo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <input
              id="single-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeUpload}
            />
            <label htmlFor="single-image-upload" style={{ width: "30%" }}>
              <IconButton
                style={{ width: "100%", padding: "10px" }}
                color="primary"
                aria-label="upload picture"
                component="span"
                className="upload-button"
              >
                Image
              </IconButton>
            </label>

            {imagePreviewUrl ? (
              <img
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                src={imagePreviewUrl}
                alt="Preview of the selected file"
                className="image-preview"
              />
            ) : image_url ? (
              <img
                src={`https://bardawamplus.com/api/storage/${image_url}`}
                alt="Uploaded file preview"
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                className="image-preview"
              />
            ) : null}
          </Form.Item>
        </div>

        {/* //todo this is name  */}
        <div className="NameAdders">
          <Form.Item name="name">
            <Button type="text" className="ButtonName" onClick={showModalNew}>
              <div className="OnButton">
                Name
                <DownOutlined />
              </div>
            </Button>

            <Modal
              title="Name"
              open={isModalOpenNew}
              onOk={handleOkNew}
              onCancel={handleCancelNew}
              footer={
                <div>
                  <Button
                    type="primary"
                    onClick={handleOkNew}
                    style={{
                      width: "30%",
                    }}
                  >
                    save
                  </Button>
                  <span> </span>
                  <Button onClick={handleCancelNew}>cancel</Button>
                </div>
              }
            >
              <Form
                form={form}
                onFinishFailed={onFinishFailed}
                initialValues={{ remember: true }}
              >
                <Form.Item name="TitleKurdish" label="Kurdish">
                  <Input placeholder="Type your Title Kurdish" />
                </Form.Item>
                <Form.Item name="TitleArabic" label="Arabic">
                  <Input placeholder="Type your Title Arabic" />
                </Form.Item>
              </Form>
            </Modal>
          </Form.Item>
        </div>
        {/* //todo ----------------------------------------------- */}

        <Form.Item name="Price" label="Price">
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="Type your Price"
          />
        </Form.Item>
        <Form.Item name="Discount" label="Discount">
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="Type your discount"
          />
        </Form.Item>
        <Form.Item name="points" label="points">
          <Input
            style={{ width: "40%", paddingLeft: 20 }}
            placeholder="Type your points"
          />
        </Form.Item>
        {/* //todo this is description */}
        <div className="NameAdders">
          <Form.Item name="name">
            <Button type="text" className="ButtonName" onClick={showModalNew2}>
              <div className="OnButton">
                Description
                <DownOutlined />
              </div>
            </Button>

            <Modal
              title="Description"
              open={isModalOpenNew2}
              onOk={handleOkNew2}
              onCancel={handleCancelNew2}
              footer={
                <div>
                  <Button
                    type="primary"
                    onClick={handleOkNew2}
                    style={{
                      width: "30%",
                    }}
                  >
                    save
                  </Button>
                  <span> </span>
                  <Button onClick={handleCancelNew2}>cancel</Button>
                </div>
              }
            >
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{ remember: true }}
              >
                <Form.Item
                  name="DescriptionKurdish"
                  label="Description Kurdish"
                >
                  <TextArea
                    showCount
                    maxLength={2000}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    placeholder="Type your Title Kurdish"
                  />
                </Form.Item>
                <Form.Item name="DescriptionArabic" label="Description Arabic">
                  <TextArea
                    showCount
                    maxLength={2000}
                    // onChange={onChange}
                    style={{
                      height: 65,
                      resize: "none",
                    }}
                    placeholder="Type your Title Arabic"
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Form.Item>
        </div>
        <Form.Item label="VIP" name="pinned" valuePropName="checked">
          <Switch></Switch>
        </Form.Item>
        {/* //! ----------------------------------------------- */}

        <Form.Item label=".">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <span style={{ margin: "0 8px" }}></span>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateProducts;
